<template>
  <div>

    <cashier-popup v-if="cashierPopup" @close-popup="togglePopup" :cashierId="cashierId"/>

    <vs-row vs-w="12">
       <div class="flex flex-wrap-reverse items-center data-list-btn-container">

         <vs-button @click="activePrompt=true" class="mr-2">{{$t('Export')}}</vs-button>
         <vs-button
          color="primary"
          type="gradient"
          icon-pack="feather"
          icon="icon-plus"
          @click="addNewCashier">
          {{$t('Create Cashier')}}
        </vs-button>
        </div>
 <vs-prompt :title="$t('Export To Excel')" class="export-options" @cancle="clearFields" @accept="exportToExcel" :accept-text="$t('Export')"  :cancel-text="$t('Cancel')" @close="clearFields" :active.sync="activePrompt">
        <vs-input v-model="fileName" :placeholder="$t('Enter File Name..')" class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex mt-4">
           <span class="mr-2">{{$t('Cell Auto Width')}}</span>
          <vs-switch v-model="cellAutoWidth">{{$t('Cell Auto Width')}}</vs-switch>
        </div>
    </vs-prompt>
      <vs-col vs-w="12">

        <div id="data-list-list-view" class="data-list-container">

          <vs-pagination-table model_type="cashier" :data="cashiers" :table_name="$t('Cashiers')">
            <template slot-scope="{data}">
              <template>
                <!-- <vs-th sort-key="id">{{$t('ID')}}</vs-th> -->
                <vs-th sort-key="name">{{$t('Cashier Name')}}</vs-th>
                <vs-th>{{$t('Income')}}</vs-th>
                <vs-th>{{$t('Outcome')}}</vs-th>
                <vs-th>{{$t('Balance')}}</vs-th>

                <vs-th>{{$t('Options')}}</vs-th>
              </template>
              <template>
                <vs-tr :key="index" v-for="(tr, index) in data">
                  <!-- <vs-td>
                    <vs-chip transparent color="primary">
                      {{ tr.id }}
                    </vs-chip>
                  </vs-td> -->
                  <vs-td>
                    <router-link
                        class="ml-1 mr-1"
                        :to="{
                        name: 'cashier-details',
                        params: { cashierId: tr.id }
                      }"
                      >
                    {{ tr.name }}
                    </router-link>
                  </vs-td>
                  <vs-td v-bind:style=" tr.income ? 'min-width:150px' : '' ">
                    <font-awesome-icon
                      color="rgb(var(--vs-success))"
                      :icon="['fas', 'arrow-down']"
                    />
                    {{ tr.income ? tr.income+' TL' : '-' }}
                  </vs-td>
                  <vs-td v-bind:style=" tr.outcome ? 'min-width:150px' : '' ">
                    <font-awesome-icon
                      color="rgb(var(--vs-danger))"
                      :icon="['fas', 'arrow-up']"
                    />
                    {{ tr.outcome ? tr.outcome+' TL' : '-' }}
                  </vs-td>
                  <vs-td v-bind:style=" tr.balance ? 'min-width:150px' : '' ">
                    {{ tr.balance }} TL
                  </vs-td>

                  <vs-td v-if="false">
                    <div style="width: max-content">
                      <vs-chip transparent color="success">
                        <vs-avatar
                          color="rgb(var(--vs-success))"
                          icon="event_available"
                        />
                        <vx-tooltip color="success" :text="tr.created_from">
                          {{ tr.created_at }}
                        </vx-tooltip>
                      </vs-chip>
                    </div>
                    <div style="width: max-content">
                      <vs-chip transparent color="warning">
                        <vs-avatar color="rgb(var(--vs-warning))" icon="update"/>
                       <vx-tooltip
                          transparent
                          color="warning"
                          :text="tr.updated_from">
                          {{ tr.updated_at }}
                        </vx-tooltip>
                      </vs-chip>
                    </div>
                  </vs-td>
                  <vs-td>
                    <div class="auto-cols-max">
                      <!-- <router-link
                        class="ml-1 mr-1"
                        :to="{
                        name: 'cashier-details',
                        params: { cashierId: tr.id }
                      }"
                      >
                        <font-awesome-icon
                          color="rgb(var(--vs-warning))"
                          :icon="['fas', 'exchange-alt']"
                        />
                      </router-link> -->
                      <a
                        class="ml-1 mr-1"
                        href="javascript:void(0)"
                        @click="editCashier(tr.id)"
                      >
                        <font-awesome-icon
                          color="rgb(var(--vs-success))"
                          :icon="['fas', 'edit']"
                        />
                      </a>
                      <a
                        class="ml-1 mr-1"
                        href="javascript:void(0)"
                        @click="confirmDelete(tr.id)"
                      >
                        <font-awesome-icon
                          color="rgb(var(--vs-danger))"
                          :icon="['fas', 'trash-alt']"
                        />
                      </a>

                      <span class="ml-1 mr-1">
                      <vs-dropdown
                      v-if="false">
                        <a href="#">
                          <font-awesome-icon
                            color="rgb(var(--vs-primary))"
                            :icon="['fas', 'ellipsis-v']"
                          />
                        </a>

                        <vs-dropdown-menu>
                          <vs-dropdown-item>
                            {{$t('Create Transaction')}}
                          </vs-dropdown-item>

                          <vs-dropdown-item divider>
                            {{$t('View All Transaction')}}
                          </vs-dropdown-item>
                          <vs-dropdown-item divider>
                            {{$t('post this cashier')}}
                          </vs-dropdown-item>
                          <vs-dropdown-item divider>
                            {{$t('view posting operation')}}
                          </vs-dropdown-item>
                          <vs-dropdown-item divider>
                            {{$t('Cashier Details')}}
                          </vs-dropdown-item>
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </span>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </template>
          </vs-pagination-table>

        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
/* eslint-disable */
import CashierPopup from "@/views/pages/cashier/CashierPopup";
import axios from '../../../axios'
import vSelect from 'vue-select'

export default {
  components: {
    CashierPopup,
    vSelect
  },
  computed:{
    cashiers() {
      return this.$store.getters["cashier/cashiers"]
    },
  },
  data() {
    return {
      cashierPopup: false,
      cashierId: '',
      //
      fileName: '',
      formats:['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      headerTitle: ['Id', 'Name', 'Incomming','Outcomming','Balance', 'Last Transaction'],
      headerVal: ['id','name', 'income','outcome', 'balance', 'last_transaction'],
      activePrompt: false
    };
  },
  methods: {
    exportToExcel () {
      axios.get('/cashiers?nopaginate=true').then(response=>{
        import('../../../components/excel/ExportExcel').then(excel => {
        const list = response.data.cashiers
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat || 'xlsx'
        })
        this.clearFields()
      })
      })

    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
         return v[j]
      }))
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    confirmDelete(el) {
      this.cashierId = el
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: this.$t('Are you sure you want to delete this Cashier'),
        accept: this.acceptAlert,
        acceptText: this.$t('Delete'),
        cancelText: this.$t('Cancel')
      })
    },
    acceptAlert() {
      this.$store.dispatch('cashier/DeleteCashier', this.cashierId).then(()=>{
          this.$store.dispatch("cashier/GetData")
      })

    },
    addNewCashier() {
      this.cashierId = ''
      this.togglePopup(true)
    },
    editCashier(cash_id) {
      this.cashierId = cash_id
      this.togglePopup(true)
    },
    togglePopup(val = false) {
      this.cashierPopup = val
    }
  },
  created() {
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
