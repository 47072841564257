
<template>
  <div>
    <vs-popup :title="(this.cashierId === '' ? $t('Add New Cashier') : $t('Update Cashier'))" :active.sync="popupActive">
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-w="12">
          <vs-input class="mt-5 w-full" :label-placeholder="$t('Cashier Name')" v-model="localCashier.name"/>
        </vs-col>
        <vs-col>
          <vs-alert class="w-full mb-5" color="danger" v-show="errors.name" v-if="errors.name" active="true">
            {{$t(errors.name[0])}}
          </vs-alert>
        </vs-col>
        <vs-col class="mt-5" vs-type="flex" vs-w="12" vs-justify="flex-end">
          <vs-button @click="saveCashier">{{$t("Save")}}</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>

export default {
  /* eslint-disable */
  name:"CashierPopup",
  props:['cashierId'],
  data () {
    return {
      popupActive:true,
      localCashier:{},
      errors:[]
    }
  },
  watch:{
    popupActive(val){
      if(!val){
        setTimeout(()=>{
          this.$emit('close-popup')
        },1)
      }
    },
    cashier(val){
      this.localCashier={...val}
    }
  },
  computed:{
    cashier(){
      return this.$store.getters["cashier/cashier"]
    }
  }
  ,
  methods: {
    getData(){
      if(this.cashierId !== ''){
        this.$store.dispatch("cashier/getCashier", this.cashierId)
      }
    },
    saveCashier(){
      if(this.cashierId === ''){
        this.$store.dispatch("cashier/storeCashier",this.localCashier).then(()=>{
          this.popupActive = false
        }
        ).catch((error) => {
          if(error && error.response && error.response.data && error.response.data.errors)
            this.errors = error.response.data.errors
        })
      } else {
        this.$store.dispatch("cashier/updateCashier",this.localCashier).catch((error) => {
          if(error.response.data.errors)
            this.errors = error.response.data.errors
        })
      }
    }
  },
  created() {
    this.getData()
  }
}
</script>
